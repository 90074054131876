import React from 'react';

import hero from '../../images/home/landing/hero.png';
// import harvard from '../../images/home/landing/harvard.png';
// import mit from '../../images/home/landing/mit.png';
import Phone from './Phone';
import Email from './Email';
import { getSectionContent } from '../../utils/general';
import {
	HeaderLanding,
	HeroImg,
	PhoneWrapper,
	LandingCaption,
	Wrapper,
	SectionWrapper,
	SectionImgContainer,
	LandingTitleText,
} from '../../styles';

const Landing = ({ section, content, num, notFullPage, image, email }) => {
	const title = getSectionContent(num, 'title', content);
	const subtitle = getSectionContent(num, 'subtitle', content);
	const caption = getSectionContent(num, 'caption', content);

	return (
		<div>
			<SectionWrapper className={notFullPage ? 'notFullPage' : ''}>
				<Wrapper>
					<HeaderLanding dangerouslySetInnerHTML={{ __html: title?.text }} />
					{subtitle ? <LandingTitleText dangerouslySetInnerHTML={{ __html: subtitle?.text }} /> : null}
					<PhoneWrapper email={email}>{email ? <Email /> : <Phone />}</PhoneWrapper>
					{caption ? <LandingCaption dangerouslySetInnerHTML={{ __html: caption?.text }} /> : null}
					{/* <Clients row wrap>
						<ClientWrapper justifyEnd alignStart>
							<ClientText>founded at</ClientText>
							<ClientImg src={harvard} alt="harvard" />
						</ClientWrapper>
						<ClientWrapper justifyEnd alignStart>
							<ClientText>pre-seed funding by</ClientText>
							<ClientImg src={mit} alt="mit" />
						</ClientWrapper>
					</Clients> */}
				</Wrapper>
				<SectionImgContainer>
					<HeroImg src={image ?? hero} alt="hero" className={section} />
				</SectionImgContainer>
			</SectionWrapper>
		</div>
	);
};

export default Landing;
