import React, { useState, useEffect, memo } from 'react';
// import loadable from '@loadable/component';

// const FlareComponent = loadable(() => import('flare-react'));

import { useWindowSize } from '../../utils/general';

const SectionFlare = ({ view, file, animation }) => {
	const [FlareComponentImport, setFlareComponentImport] = useState();
	const [playController, setplayController] = useState();
	const [viewing, setviewing] = useState(view);
	const { width, height } = useWindowSize();

	useEffect(() => {
		if (view && !viewing) setviewing(true);
		if (!view && viewing) setviewing(false);
	}, [view]);

	useEffect(() => {
		if (viewing) {
			async function fetchFlare() {
				var FlareComponentImportTemp = FlareComponentImport || (await import('flare-react'));
				if (!FlareComponentImport) setFlareComponentImport(FlareComponentImportTemp);

				class FlareController extends FlareComponentImportTemp.default.Controller {
					constructor({ customAnimation }) {
						super();
						this._Play = null;
						this._playTime = 0;
						this.customAnimation = customAnimation;
					}

					initialize(artboard) {
						this._Play = artboard.getAnimation(this.customAnimation);
					}

					advance(artboard, elapsed) {
						// advance the walk time
						this._playTime += elapsed;
						const { _Play: play, _playTime: playTime } = this;

						if (playTime >= play.duration) {
							return false;
						}
						play.apply(playTime % play.duration, artboard, 1.0);
						return true;
					}
				}

				setplayController(new FlareController({ customAnimation: animation }));
			}
			fetchFlare();
		}
	}, [viewing]);

	return FlareComponentImport ? (
		<FlareComponentImport.default
			width={getFlareSize(true, width, height)}
			height={getFlareSize(false, width, height)}
			file={file}
			transparent={true}
			controller={playController}
		/>
	) : (
		<div />
	);
};

export default memo(SectionFlare);

const getFlareSize = (getWidth, width, height) => {
	if (getWidth) {
		if (width > 1200) return 500;
		if (width > 992) return 450;
		if (width > 768) return 340;
		if (width > 576) return width - 40;
		if (width > 384) return width - 20;
	} else {
		if (width > 1200) return 500;
		if (width > 992) return 450;
		if (width > 768) return 340;
		if (width > 576) return width - 40;
		if (width > 384) return width - 20;
	}
};
