import React, { useState } from 'react';
import { httpsCallable } from 'firebase/functions';

import { functions } from '../../utils/firebase';
import Emoji from './Emoji';
import { EmailInput, SignUpButton, PhoneInput, FormError, Body, PhoneForm } from '../../styles';
import { graphql, StaticQuery } from 'gatsby';

export const Email = () => {
	const [error, setError] = useState('');
	const [email, setEmail] = useState('');
	const [name, setName] = useState('');
	const [zip, setZip] = useState('');
	const [button, setButton] = useState('');
	const [done, setDone] = useState(false);
	const [clicked, setClicked] = useState(false);

	const handleSubmit = async event => {
		event.preventDefault();
		if (clicked) return;
		setClicked(true);
		setButton('sending');

		// Sending request for Customer.io
		const callable = httpsCallable(functions, 'joinInvestments');
		return callable({ email })
			.then(res => {
				const formData = new FormData();
				formData.append('email', email.toLowerCase());

				// Sending email to Google Sheets
				fetch(
					'https://script.google.com/macros/s/AKfycbzR-bNNJiNb5iH1imJRXprz-wpwkAYUbJ2n6wACxjyO4dDPOSdKZaIctBMP1RURsx6G/exec',
					{
						method: 'POST',
						body: formData,
					}
				);
				setButton('');
				setDone(true);
			})
			.catch(() => {
				setError('Something went wrong. Please try again later.');
				setButton('');
				setTimeout(() => {
					setError('');
				}, 1500);
			})
			.finally(() => {
				setClicked(false);
			});
	};

	const handleSubmitExtras = async event => {
		event.preventDefault();
		if (clicked) return;
		setClicked(true);

		if (!name || !zip) {
			setError('Please provide all fields');
			setTimeout(() => {
				setError('');
			}, 2000);
			return;
		}

		setButton('sending');

		// Sending request for Customer.io
		const callable = httpsCallable(functions, 'joinInvestments');
		return callable({ email, zip, name })
			.then(res => {
				const formData = new FormData();
				formData.append('email', email.toLowerCase());
				formData.append('name', name.toLowerCase());
				formData.append('zip', zip.toLowerCase());

				// Sending email to Google Sheets
				fetch(
					'https://script.google.com/macros/s/AKfycbzR-bNNJiNb5iH1imJRXprz-wpwkAYUbJ2n6wACxjyO4dDPOSdKZaIctBMP1RURsx6G/exec',
					{
						method: 'POST',
						body: formData,
					}
				);
				setButton('done');
				setDone(true);
				setTimeout(() => {
					setButton('');
					setName('');
					setEmail('');
					setZip('');
					setDone(false);
				}, 2500);
			})
			.catch(() => {
				setError('Something went wrong. Please try again later.');
				setButton('');
				setTimeout(() => {
					setError('');
				}, 1500);
			})
			.finally(() => {
				setClicked(false);
			});
	};

	return (
		<StaticQuery
			query={graphql`
				query {
					allGoogleInvestmentsSheet {
						edges {
							node {
								position
								text
							}
						}
					}
				}
			`}
			render={({ allGoogleInvestmentsSheet }) => {
				const getText = input =>
					allGoogleInvestmentsSheet.edges.find(({ node: { position } }) => position === input)?.node?.text ?? '';

				return !done ? (
					<div>
						<PhoneForm onSubmit={handleSubmit}>
							<EmailInput
								required
								placeholder="email"
								value={email}
								type="email"
								onChange={e => setEmail(e.target.value?.toLowerCase())}
							/>
							<SignUpButton round submit className={button}>
								{getText('form1-button1')}
							</SignUpButton>
							<SignUpButton round submit className={button}>
								{getText('form1-button2')}
							</SignUpButton>
							{error && <FormError>{error}</FormError>}
						</PhoneForm>
					</div>
				) : (
					<>
						<div style={{ width: '360px' }}>
							<Body center font="0.85em">
								<Emoji symbol="🎉" label="Party Popper" /> {getText('form2-title1')}
								<br />
								{getText('form2-title2')}
							</Body>
							<EmailInput
								required
								type="text"
								placeholder="name"
								value={name}
								onChange={e => setName(e.target.value)}
							/>
						</div>
						<div style={{ height: '15px' }} />
						<PhoneForm onSubmit={handleSubmitExtras}>
							<EmailInput
								required
								placeholder="zip"
								value={zip}
								type="text"
								onChange={e => setZip(e.target.value?.toLowerCase())}
							/>
							<SignUpButton round submit className={button}>
								{getText('form2-button1')}
							</SignUpButton>
							<SignUpButton round submit className={button}>
								{getText('form2-button2')}
							</SignUpButton>
							<SignUpButton round submit className={button}>
								{getText('form2-button3')}
							</SignUpButton>
							{error && <FormError>{error}</FormError>}
						</PhoneForm>
					</>
				);
			}}
		/>
	);
};

export default Email;
