import React from 'react';

import { ListItemImage, ListItemWrapper } from '../../styles';

const ListItemCheckMark = ({ children }) => (
	<ListItemWrapper row>
		<ListItemImage />
		{children}
	</ListItemWrapper>
);

export default ListItemCheckMark;
