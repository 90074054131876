/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useRef } from 'react';

import DownloadButton from './DownloadButton';
import SectionFlare from './Flare';
import Email from './Email';
import ListItemCheckMark from './ListItem';
import { getSectionContent } from '../../utils/general';
import useIntersection from '../../hooks/visible';
import {
	SectionHeader,
	SectionImgContainer,
	Wrapper,
	SectionSubHeader,
	SectionWrapper,
	Body,
	DownloadButtonWrapper,
} from '../../styles';

const Section = ({
	view,
	content,
	num,
	animationName,
	animationFile,
	reverse,
	notFullPage,
	email,
	showButton = true,
	buttonComponent,
}) => {
	const ref = useRef();
	const inViewport = notFullPage ? useIntersection(ref, '0px') : false;
	const isInView = notFullPage ? (inViewport ? 'view' : '') : view;

	const top = getSectionContent(num, 'top', content);
	const title = getSectionContent(num, 'title', content);
	const list = getSectionContent(num, 'list', content);

	const handleClick = () => window.scrollTo({ top: 0, behavior: 'smooth' });

	return (
		<div ref={ref}>
			<SectionWrapper reverse={!!reverse} className={notFullPage ? 'notFullPage' : ''}>
				{reverse ? (
					<SectionImgContainer className={isInView}>
						<SectionFlare animation={animationName} file={animationFile} view={isInView} />
					</SectionImgContainer>
				) : null}
				<Wrapper>
					{top ? <SectionSubHeader>{top?.text}</SectionSubHeader> : null}
					{title ? <SectionHeader>{title?.text}</SectionHeader> : null}
					{list
						? Object.entries(list)?.map((node, i) => {
								var text = node[1];
								if (node[0] !== 'position' && text !== null) {
									return (
										<ListItemCheckMark key={text + i}>
											<Body>{text}</Body>
										</ListItemCheckMark>
									);
								}
						  })
						: null}
					{email ? (
						<DownloadButtonWrapper className="minWidth" onClick={handleClick}>
							Join Waitlist
						</DownloadButtonWrapper>
					) : showButton ? (
						<DownloadButton alignStart />
					) : (
						buttonComponent ?? null
					)}
				</Wrapper>
				{!reverse ? (
					<SectionImgContainer className={isInView}>
						<SectionFlare animation={animationName} file={animationFile} view={isInView} />
					</SectionImgContainer>
				) : null}
			</SectionWrapper>
		</div>
	);
};

export default Section;
