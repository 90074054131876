import React, { useState } from 'react';

import SignUp from './SignUp';
import { DownloadButtonWrapper, Wrapper, StyledModal, ModalClose } from '../../styles';

const DownloadButton = props => {
	const [isOpen, setIsOpen] = useState(false);

	return (
		<Wrapper {...props}>
			<StyledModal isOpen={isOpen} onBackgroundClick={() => setIsOpen(false)} onEscapeKeydown={() => setIsOpen(false)}>
				<ModalClose onClick={() => setIsOpen(false)}>&times;</ModalClose>
				<SignUp isOpen={isOpen} />
			</StyledModal>
			<DownloadButtonWrapper
				onClick={() => setIsOpen(true)}
				// onClick={() => extLinkClick("https://envel.app.link/get")}
			>
				Get started
			</DownloadButtonWrapper>
		</Wrapper>
	);
};

export default DownloadButton;
